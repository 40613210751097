<template>
    <v-container fluid>

        <div id="spec" class="pt-16">
            <vue-simple-markdown :source="specification"></vue-simple-markdown>
        </div>
    </v-container>
</template>

<script>

    const text = require('../specification/v1.0.md');

    export default {
        name: 'HelloWorld',

        data: () => ({
            specification: 'foo'
        }),
        methods: {},
        created() {

            this.specification = text
        }
    }
</script>

<style scoped>
</style>
