<template>
    <v-container fluid>

        <p>
            The Data Quality Index (DQI) is an open framework for communicating the characteristics of data sets. The
            DQI shows how the dataset is matching the expectations of the user.
        </p>

        <v-container fluid>


            <v-row>

                <v-col col="12" sm="8">

                    <v-card elevation="2">
                        <v-card-text>

                            <h1 class="mb-2">
                                Data Quality Index Version 1.0 Calculator
                            </h1>

                            <p>
                                Hover over metric values for a summary of the information in the official DQI v1.0
                                Specification Document.
                                The Specification is available in the links on the top, along with a User Guid providing
                                additional scoring
                                guidance and examples.
                            </p>

                            <v-row>
                                <v-col col="12" sm="4">
                                    <v-text-field
                                            :value="dqiQualitative() + ' - ' + dqiScore()"
                                            label="DQI Score"
                                            readonly
                                    ></v-text-field>
                                </v-col>

                                <v-col col="12" sm="8">
                                    <v-text-field
                                            :value="vector()"
                                            label="DQI Vector"
                                            readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-for="section of dqi.structure" v-bind:key="section.name" no-gutters>

                                <v-col cols="12" class="pb-0">
                                    <h2>{{section.name}}</h2>
                                </v-col>

                                <v-col cols="4" v-for="question of section.questions" v-bind:key="question.code">
                                    <v-radio-group v-model="dqi.model[question.code]" mandatory>
                                        <template v-slot:label>
                                            <div><h3>{{question.name}}</h3></div>
                                        </template>
                                        <v-radio :value="option.code" v-for="option of question.values"
                                                 v-bind:key="option.code">
                                            <template v-slot:label>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                <span
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    {{option.name}}
                                </span>
                                                    </template>
                                                    <div class="dqi-tooltip">
                                                        {{option.tooltip}}
                                                    </div>
                                                </v-tooltip>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col col="12" sm="4">
                    <v-card elevation="2">
                        <v-card-title>Link to your result</v-card-title>
                        <v-card-text>
                            <p>
                                Use the link generated here to immediately decode your DQI Vector
                            </p>
                            <v-text-field
                                    :value="url()"
                                    label="Link"
                                    readonly
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>


        </v-container>

    </v-container>
</template>

<script>
    export default {
        name: 'HelloWorld',

        data: () => ({
            dqi: {
                structure: [
                    {
                        name: "Completeness",
                        questions: [
                            {
                                name: "Population Coverage",
                                code: "PC",
                                values: [
                                    {
                                        name: "Whole",
                                        code: "W",
                                        tooltip: "The data covers the whole possible event space or state. There are no events or entities missing. For example, if the dataset represents the medical records in a specific country, it covers everybody from the country."
                                    },
                                    {
                                        name: "Representative Sample",
                                        code: "R",
                                        tooltip: "The data is a representative sample of the event space or state. There are some events or entities missing from the data, however it’s believed that missing data is the result of a random error, not a systematic one. For example, if the dataset represents political preferences it covers representative samples gathered across all regions, social groups and ages."
                                    },
                                    {
                                        name: "Segment",
                                        code: "S",
                                        tooltip: "Very scarce data describing a much more complex situation and/or there is high probability that a systematic error occurs. For example, the data representing political preferences that omit statistically significant social groups, or one collected by statisticians incorrectly capturing preferences by biasing interviewed people."
                                    }
                                ]
                            },
                            {
                                name: "Continuity",
                                code: "C",
                                values: [
                                    {
                                        name: "Continuous Stream",
                                        code: "C",
                                        tooltip: "The data is continuously updated with specified frequency or real time, reflecting the events or state of the modeled source. It may be partitioned by dates."
                                    },
                                    {
                                        name: "Sample",
                                        code: "S",
                                        tooltip: "The data is a sample reflecting events or the state of the modeled source from a specific time-period. "
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        name: "Methodological Soundness",
                        questions: [
                            {
                                name: "Derivation Method",
                                code: "DM",
                                values: [
                                    {
                                        name: "Direct",
                                        code: "D",
                                        tooltip: "The data is a direct representation of the events or the system state."
                                    },
                                    {
                                        name: "Calculation",
                                        code: "C",
                                        tooltip: "The data was calculated based on the events or the system state. It usually applies to the data representing conclusions or aggregations based on primary data sources."
                                    },
                                    {
                                        name: "Heuristic",
                                        code: "H",
                                        tooltip: "The data was generated using heuristics. It’s usually the case when there is no other way of deriving the data. The data represents the best guess of the data authors, often based on their experience used to reason about premises that are likely to describe wanted space."
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        name: "Timeliness",
                        questions: [
                            {
                                name: "Availability Delay",
                                code: "AD",
                                values: [
                                    {
                                        name: "None",
                                        code: "N",
                                        tooltip: "The data is available in near real-time or with almost no delay compared to the event or state modeled by the data."
                                    },
                                    {
                                        name: "Hours",
                                        code: "H",
                                        tooltip: "There is an expected delay of up to 24 hours between the events or the state modeled by the data and their availability in the data set."
                                    },
                                    {
                                        name: "Days",
                                        code: "D",
                                        tooltip: "There is an expected delay of up to 30 days between the events or the state modeled by the data and their availability in the data set."
                                    },
                                    {
                                        name: "Months",
                                        code: "M",
                                        tooltip: "The data set is generated after a month or longer since the events or the state modeled by the data occurred."
                                    },
                                ]
                            },
                            {
                                name: "Collection Method",
                                code: "CM",
                                values: [
                                    {
                                        name: "Native",
                                        code: "N",
                                        tooltip: "The data collection is native to the system processing events or state. It’s usually the case for databases used directly by the system, or systems emitting events."
                                    },
                                    {
                                        name: "Third Party Automation",
                                        code: "T",
                                        tooltip: "The data is collected by a third-party automation. It may be a system or script collecting events emitted by the main system, or browsing its state periodically."
                                    },
                                    {
                                        name: "Manual",
                                        code: "M",
                                        tooltip: "The data is collected manually."
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        name: "Reliability",
                        questions: [
                            {
                                name: "Support",
                                code: "S",
                                values: [
                                    {
                                        name: "24/7 Support",
                                        code: "A",
                                        tooltip: "The data collection is supported 24 hours a day and 7 days a week."
                                    },
                                    {
                                        name: "Business Hours",
                                        code: "B",
                                        tooltip: "The data collection is supported during business hours in the timezone specific to the data owners."
                                    },
                                    {
                                        name: "Best Effort",
                                        code: "E",
                                        tooltip: "The data collection is supported on the best effort basis. There is intention to fix potential problems, but without any guarantees."
                                    },
                                    {
                                        name: "Not Supported",
                                        code: "N",
                                        tooltip: "The data collection is not supported. It may fail without notice and won’t be fixed. "
                                    },
                                ]
                            },
                            {
                                name: "Monitoring",
                                code: "M",
                                values: [
                                    {
                                        name: "Availability",
                                        code: "A",
                                        tooltip: "The data collection process is monitored, detecting any errors leading to the loss of the data."
                                    },
                                    {
                                        name: "None",
                                        code: "N",
                                        tooltip: "The data collection process is not monitored. "
                                    },
                                ]
                            },
                            {
                                name: "Verification",
                                code: "V",
                                values: [
                                    {
                                        name: "Automatic",
                                        code: "A",
                                        tooltip: "The data collection process is automatically monitored or tested, detecting any errors damaging data correctness."
                                    },
                                    {
                                        name: "Manual",
                                        code: "M",
                                        tooltip: "The data collection process was manually tested for correctness."
                                    },
                                    {
                                        name: "None",
                                        code: "N",
                                        tooltip: "The data collection process is not tested."
                                    },
                                ]
                            }
                        ]
                    }
                ],
                model: {
                    PC: null,
                    C: null,
                    AD: null,
                    CM: null,
                    DM: null,
                    S: null,
                    M: null,
                    V: null,
                },
                weights: {
                    PC: {
                        W: 1,
                        R: 0.7,
                        S: 0.15
                    },
                    C: {
                        C: 1,
                        S: 0.5
                    },
                    AD: {
                        N: 1,
                        H: 0.95,
                        D: 0.85,
                        M: 0.7
                    },
                    CM: {
                        N: 1,
                        T: 0.95,
                        M: 0.8
                    },
                    DM: {
                        D: 1,
                        C: 0.95,
                        H: 0.47,
                    },
                    S: {
                        A: 1.1,
                        B: 1,
                        E: 0.95,
                        N: 0.8,
                        overwrite: {
                            C: {
                                S: {
                                    E: 1,
                                    N: 1
                                }
                            }
                        }
                    },
                    M: {
                        A: 1,
                        N: 0.95
                    },
                    V: {
                        A: 1.02,
                        M: 0.91,
                        N: 0.63
                    }
                },
                qualitative: [
                    {
                        name: "Excellent",
                        min: 9,
                        max: 10
                    },
                    {
                        name: "High",
                        min: 7,
                        max: 8.9
                    },
                    {
                        name: "Medium",
                        min: 4,
                        max: 6.9
                    },
                    {
                        name: "Low",
                        min: 0.1,
                        max: 3.9
                    },
                    {
                        name: "None",
                        min: 0,
                        max: 0
                    },

                ]
            }
        }),
        methods: {
            dqiScore() {
                let css = 5 * this.dqi.weights.PC[this.dqi.model.PC] * this.dqi.weights.C[this.dqi.model.C] + 5 * this.dqi.weights.DM[this.dqi.model.DM];
                let timeliness = this.dqi.weights.AD[this.dqi.model.AD] * this.dqi.weights.CM[this.dqi.model.CM];
                let reliability;
                if (this.dqi.model.C === "S" && ["N", "E"].includes(this.dqi.model.S)) {
                    reliability = this.dqi.weights.S.overwrite.C.S[this.dqi.model.S] * this.dqi.weights.M[this.dqi.model.M] * this.dqi.weights.V[this.dqi.model.V];
                } else {
                    reliability = this.dqi.weights.S[this.dqi.model.S] * this.dqi.weights.M[this.dqi.model.M] * this.dqi.weights.V[this.dqi.model.V];
                }

                return Math.min(Math.floor(css * timeliness * reliability * 10) / 10, 10);
            },
            dqiQualitative() {
                let score = this.dqiScore();
                if (score) {
                    let {name} = this.dqi.qualitative.find(({min, max}) => score <= max && score >= min);
                    return name;
                }
            },
            url() {
                return "https://dqi.luciow.org?dqi=" + this.vector();
            },
            vector() {
                return "DQI:1.0" +
                    `/PC:${this.dqi.model.PC}` +
                    `/C:${this.dqi.model.C}` +
                    `/DM:${this.dqi.model.DM}` +
                    `/AD:${this.dqi.model.AD}` +
                    `/CM:${this.dqi.model.CM}` +
                    `/S:${this.dqi.model.S}` +
                    `/M:${this.dqi.model.M}` +
                    `/V:${this.dqi.model.V}`
            }
        },
        created() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let dqi = urlParams.get('dqi');
            if (dqi) {
                //parse
                try {
                    let split = dqi.split('/')
                    if (split[0] !== 'DQI:1.0') {
                        throw "Incorrect prefix"
                    } else {
                        split.shift()
                        for (let rate of split) {
                            let [question, score] = rate.split(":")
                            if (!question || !score) {
                                throw "Missing question code or score"
                            } else {
                                if (!this.dqi.weights[question]) {
                                    throw "The question code doesnt exists"
                                }
                                if (!this.dqi.weights[question][score]) {
                                    throw "The question score doesnt exists"
                                }
                                this.dqi.model[question] = score
                            }
                        }
                    }
                } catch (e) {
                    console.log(e)
                    alert('The ' + dqi + ' is not a correct DQI vector')
                }
            }
        }
    }
</script>

<style scoped>
    .dqi-tooltip {
        max-width: 400px;
    }
</style>
