<template>
    <v-app>
        <v-app-bar
                app
                color="secondary"
                dark
        >
            <div class="d-flex align-center">
                <v-avatar
                        tile
                        size="48">
                    <v-icon color="white" large>mdi-database-eye</v-icon>
                </v-avatar>

                <span class="title">DQI - Data Quality Index</span> <span class="grey--text ml-2"> v1.0</span>
            </div>

            <v-spacer></v-spacer>


            <v-btn
                    href="#spec"
                    class="mr-2"
                    text
            >
                <span>DQI v1.0 Specification Document</span>
            </v-btn>
            <v-divider
                    vertical
            ></v-divider>
            <v-btn
                    href="https://twitter.com/mariuszluciow"
                    target="_blank"
                    text
                    class="ml-2"
            >
                <v-icon color="white" large>mdi-twitter</v-icon>
            </v-btn>
            <v-btn
                    href="https://gitlab.com/org-luciow/dqi"
                    target="_blank"
                    text
            >
                <v-icon color="white" large>mdi-gitlab</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <div class="v-responsive mx-auto" style="max-width: 1200px">
                <v-container >
                    <v-row align="center"
                           justify="center">
                        <v-col>
                            <HelloWorld/>
                            <Specification/>
                        </v-col>
                    </v-row>
                </v-container>

            </div>
        </v-main>
    </v-app>
</template>

<script>
    import HelloWorld from './components/HelloWorld';
    import Specification from './components/Specification';

    export default {
        name: 'App',

        components: {
            HelloWorld, Specification
        },

        data: () => ({
            //
        }),
    };
</script>
